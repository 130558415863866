@tailwind base;
@tailwind components;
@import url("../../../packages/ui/styles/globals.css");

.dark {
  color-scheme: dark;
}

body {
  @apply bg-background;
  @apply font-body;
}

#appleid-signin {
  @apply rounded-full mt-8 overflow-hidden bg-white flex-center cursor-pointer;
  height: 46px;
}

.brighter {
  filter: brightness(140%) hue-rotate(2deg);
}

.modal-overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(45 45 45 / 75%);
}

.modal-overlay-light {
  background-color: #ffffff61;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal {
  position: relative;
  z-index: 50;
}

.flex-center {
  @apply flex items-center justify-center;
}
.center {
  @apply flex items-center justify-center;
}

select {
  @apply border-neutral-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500;
}

button:focus,
input:focus,
textarea:focus {
  @apply outline-none ring;
}

button:disabled,
input:disabled {
  cursor: not-allowed;
  opacity: 0.75;

  @apply shadow-none;
}

.outline-error {
  box-shadow: 0 0 0 3px rgb(220 0 0 / 50%);
}

.auto-open-date-input::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
  opacity: 0;
}

.skeleton-box {
  position: relative;
  overflow: hidden;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  /* stylelint-disable */
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.1) 20%,
    rgba(255, 255, 255, 0.2) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 3s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

/* iphone */

.iphone {
  position: relative;
  border-radius: 40px;
}
.notch {
  top: 0;
  border-radius: 0 0 28px 28px;
}
.notch::before,
.notch::after {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 14px;
  height: 7px;
  background-size: 50% 100%;
  background-repeat: no-repeat;
  background-image: radial-gradient(
    circle at 0 100%,
    transparent 6px,
    #222 7px
  );
}
.notch::after {
  left: 100%;
  margin-right: corner-sizepx;
  background-image: radial-gradient(
    circle at 100% 100%,
    transparent 6px,
    #222 7px
  );
}

/* calendar calendar-cards start */

#calendar-cards:hover > .calendar-card::after {
  opacity: 1;
}

.calendar-card {
  position: relative;
}

.calendar-card:hover::before {
  opacity: 1;
}

.calendar-card::before,
.calendar-card::after {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  top: 0px;
  transition: opacity 500ms;
  width: 100%;
  pointer-events: none;
}

.calendar-card::before {
  background: radial-gradient(
    700px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.025),
    transparent 50%
  );
  z-index: 3;
}

.calendar-card::after {
  background: radial-gradient(
    500px circle at var(--mouse-x) var(--mouse-y),
    rgba(255, 255, 255, 0.1),
    transparent 50%
  );
  z-index: 1;
}

.calendar-card > .calendar-card-content {
  border-radius: inherit;
  inset: 1px;
  position: absolute;
  z-index: 2;
}

/* calendar calendar-cards end */

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@tailwind utilities;
